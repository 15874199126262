import React from "react";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import DynamicSidebar from "../components/DynamicSidebar";
import FetchNewsBlock from "../components/FetchNewsBlock";
import Default from "../_contenthub/website.json";
const { newsIntroTop } = Default;

export const Head = () => {
  return (
    <HeadSeo
      title={`Our Latest News`}
      description={`Stay up to date on the latest developments from around the world with our comprehensive news feed. From breaking news to in-depth analysis, our curated collection of news articles offers stories as they happen. `}
      seoImg={`intro-block.jpg`}
    ></HeadSeo>
  );
};

const NewsPage = () => {
  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  <div className="news-intros">
                    <h1>{newsIntroTop.title}</h1>
                    <div>{newsIntroTop.text}</div>
                  </div>
                  {/* -- */}
                  <FetchNewsBlock />
                </div>

                {/* - - -  */}
                <DynamicSidebar onlyBlogs="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default NewsPage;
